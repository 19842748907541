import React, { useState } from "react";
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FiltersMenu = ({
  filters,
  setFilters,
  clearFilters,
  expanded,
  setExpanded,
  filterHasChanges,
  showOnlyFleet,
  onlyFleet,
  selectedFleetsToShow,
  handleFleetShow,
}) => {
  const [dwtValue, setDwtValue] = useState(filters.deadweight.value);
  const [lengthValue, setLengthValue] = useState(filters.length.value);
  const [m3Value, setM3Value] = useState(filters.m3.value);
  const [year, setYear] = useState(2024);
  const [tanks, setTanks] = useState(filters.tanks.value);
  const [draft, setDraft] = useState(filters.draft.value);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleProductChange = (event) => {
    setFilters({
      ...filters,
      product: {
        ...filters.product,
        value: {
          ...filters.product.value,
          [event.target.name]: event.target.checked,
        },
      },
    });
  };

  const handlePumpChange = (event) => {
    setFilters({
      ...filters,
      pump: {
        ...filters.pump,
        value: {
          ...filters.pump.value,
          [event.target.name]: event.target.checked,
        },
      },
    });
  };

  const handleTankTypeChange = (event) => {
    setFilters({
      ...filters,
      tankType: {
        ...filters.tankType,
        value: {
          ...filters.tankType.value,
          [event.target.name]: event.target.checked,
        },
      },
    });
  };

  const handleShipdwtChange = (event, newValue) => {
    setDwtValue(newValue);
  };

  const handleShipdwtChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      deadweight: {
        ...filters.deadweight,
        value: newValue,
      },
    });
  };

  const handleLengthFilterChange = (event, newValue) => {
    setLengthValue(newValue);
  };

  const handleLengthFilterChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      length: {
        ...filters.length,
        value: newValue,
      },
    });
  };

  const handleM3FilterChange = (event, newValue) => {
    setM3Value(newValue);
  };

  const handleM3FilterChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      m3: {
        ...filters.m3,
        value: newValue,
      },
    });
  };

  const handleYearFilterChange = (event, newValue) => {
    setYear(newValue);
  };

  const handleYearFilterChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      year_built: {
        ...filters.year_built,
        value: newValue,
      },
    });
  };

  const handleTanksFilterChange = (event, newValue) => {
    setTanks(newValue);
  };

  const handleTanksChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      tanks: {
        ...filters.tanks,
        value: newValue,
      },
    });
  };

  const handleDraftFilterChange = (event, newValue) => {
    setDraft(newValue);
  };

  const handleDraftChangeCommitted = (event, newValue) => {
    setFilters({
      ...filters,
      draft: {
        ...filters.draft,
        value: newValue,
      },
    });
  };

  const handleHeatingChange = (event) => {
    const value = event.target.value;
    setFilters({
      ...filters,
      heating: {
        ...filters.heating,
        value: value === "true" ? true : value === "false" ? false : value,
      },
    });
  };

  function valuetext(value) {
    return value === 0 ? value : `${value / 1000}k`;
  }

  return (
    <Grid sx={{ p: 2, width: 300 }}>
      <Typography variant="h6" component="div" textAlign="center">
        Ship Filters
      </Typography>
      <Button
        fullWidth
        onClick={() => {
          setExpanded(null);
          clearFilters();
        }}
        disabled={!filterHasChanges}
      >
        Reset Filters
      </Button>
      <Accordion
        expanded={expanded === "panel13"}
        onChange={handleAccordionChange("panel13")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13-content"
          id="panel13-header"
        >
          Fleets
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={onlyFleet}
                  onChange={(e) => showOnlyFleet(e.target.checked)}
                  disabled={selectedFleetsToShow.length === 0}
                />
              }
              label={<span style={{ fontWeight: 500 }}>Filter by fleet</span>}
            />
            {selectedFleetsToShow.length === 0 && (
              <Typography variant="body2" color="textSecondary">
                You have no fleets
              </Typography>
            )}
            {selectedFleetsToShow.map((fleet) => (
              <FormControlLabel
                key={fleet.id}
                control={
                  <Checkbox
                    disabled={!onlyFleet}
                    checked={fleet.show}
                    onChange={(e) =>
                      handleFleetShow(fleet.id, e.target.checked)
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fill: fleet.color,
                      },
                      "&.Mui-disabled .MuiSvgIcon-root": {
                        fill: `${fleet.color}80`,
                      },
                    }}
                  />
                }
                label={fleet.name}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Product type
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.ALL}
                  onChange={handleProductChange}
                  name="ALL"
                />
              }
              label="All products"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.UNKNOWN}
                  onChange={handleProductChange}
                  name="UNKNOWN"
                  disabled={filters.product.value.ALL}
                />
              }
              label="UNKNOWN"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.CPP}
                  onChange={handleProductChange}
                  name="CPP"
                  disabled={filters.product.value.ALL}
                />
              }
              label="CPP"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.DPP}
                  onChange={handleProductChange}
                  name="DPP"
                  disabled={filters.product.value.ALL}
                />
              }
              label="DPP"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.LNG}
                  onChange={handleProductChange}
                  name="LNG"
                  disabled={filters.product.value.ALL}
                />
              }
              label="LNG"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.VEGOIL}
                  onChange={handleProductChange}
                  name="VEGOIL"
                  disabled={filters.product.value.ALL}
                />
              }
              label="Vegoil"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.product.value.GAS}
                  onChange={handleProductChange}
                  name="GAS"
                  disabled={filters.product.value.ALL}
                />
              }
              label="GAS"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleAccordionChange("panel10")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10-content"
          id="panel10-header"
        >
          Pump
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.pump.value.ALL}
                  onChange={handlePumpChange}
                  name="ALL"
                />
              }
              label="All pumps"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.pump.value.UNKNOWN}
                  onChange={handlePumpChange}
                  name="UNKNOWN"
                  disabled={filters.pump.value.ALL}
                />
              }
              label="UNKNOWN"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.pump.value["SCREW PUMP"]}
                  onChange={handlePumpChange}
                  name="SCREW PUMP"
                  disabled={filters.pump.value.ALL}
                />
              }
              label="Screw pump"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.pump.value["DEEP WELL PUMP"]}
                  onChange={handlePumpChange}
                  name="DEEP WELL PUMP"
                  disabled={filters.pump.value.ALL}
                />
              }
              label="Deep well pump"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          DWT
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={1} pb={0} pt={4}>
            <Slider
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              step={1000}
              onChange={handleShipdwtChange}
              onChangeCommitted={handleShipdwtChangeCommitted}
              value={dwtValue}
              valueLabelDisplay="on"
              marks
              min={0}
              max={14000}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleAccordionChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          Length
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={1} pb={0}>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}m`}
              step={1}
              onChange={handleLengthFilterChange}
              onChangeCommitted={handleLengthFilterChangeCommitted}
              value={lengthValue}
              min={0}
              max={200}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleAccordionChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          M³
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={1} pb={0}>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}m³`}
              step={0.5}
              onChange={handleM3FilterChange}
              onChangeCommitted={handleM3FilterChangeCommitted}
              value={m3Value}
              min={0}
              max={14000}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleAccordionChange("panel8")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
        >
          Tanks
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={1} pb={0}>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}`}
              step={1}
              onChange={handleTanksFilterChange}
              onChangeCommitted={handleTanksChangeCommitted}
              value={tanks}
              min={0}
              max={30}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleAccordionChange("panel11")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11-content"
          id="panel11-header"
        >
          Tank type
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.tankType.value.ALL}
                  onChange={handleTankTypeChange}
                  name="ALL"
                />
              }
              label="All tank types"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.tankType.value.UNKNOWN}
                  onChange={handleTankTypeChange}
                  name="UNKNOWN"
                  disabled={filters.tankType.value.ALL}
                />
              }
              label="UNKNOWN"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.tankType.value["MILDSTEEL"]}
                  onChange={handleTankTypeChange}
                  name="MILDSTEEL"
                  disabled={filters.tankType.value.ALL}
                />
              }
              label="MILDSTEEL"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.tankType.value["COATED"]}
                  onChange={handleTankTypeChange}
                  name="COATED"
                  disabled={filters.tankType.value.ALL}
                />
              }
              label="COATED"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.tankType.value["STAINLESS"]}
                  onChange={handleTankTypeChange}
                  name="STAINLESS"
                  disabled={filters.tankType.value.ALL}
                />
              }
              label="STAINLESS"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleAccordionChange("panel9")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
        >
          Draft
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={1} pb={0}>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}m`}
              step={0.5}
              onChange={handleDraftFilterChange}
              onChangeCommitted={handleDraftChangeCommitted}
              value={draft}
              min={0}
              max={20}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Heating
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            name="heating"
            value={filters.heating.value}
            onChange={handleHeatingChange}
          >
            <FormControlLabel value={"all"} control={<Radio />} label="All" />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Heating"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No Heating"
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default FiltersMenu;
