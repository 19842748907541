import React, { forwardRef } from "react";
import { Grid, TextField, Autocomplete, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocationSearching } from "@mui/icons-material";
import { FixedSizeList } from "react-window";
import shipIcon from "../../../assets/sh2.svg";

const StyledTextField = styled(TextField)(({ theme }) => ({
  position: "absolute",
  zIndex: 99999,
  right: "calc(50% - 250px)",
  top: 10,
  backgroundColor: "white",
  borderRadius: "10px",
  width: "500px",
  padding: "0px !important",
  paddingRight: "0px",
  opacity: 0.9,
  "& .MuiInputBase-input": {
    textAlign: "center",
    width: "100%",
  },
  "& .MuiFilledInput-root": {
    padding: "0px",
    paddingRight: "0px !important",
  },
  [theme.breakpoints.down("sm")]: {
    width: "249px",
    right: "calc(50% - 124.5px)",
  },
}));

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 46;

  return (
    <div ref={ref}>
      <FixedSizeList
        height={Math.min(8, itemCount) * itemSize}
        width="100%"
        itemSize={itemSize}
        itemCount={itemCount}
        outerElementType={forwardRef((props, ref) => (
          <div ref={ref} {...props} {...other} />
        ))}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </FixedSizeList>
    </div>
  );
});

const VesselAutocomplete = ({
  filteredVessels,
  searchValue,
  setSearchValue,
  selectedVessel,
  setSelectedVessel,
  setCenter,
  setZoom,
  mapRef,
  filterOptions,
}) => {
  return (
    <Autocomplete
      options={filteredVessels}
      getOptionLabel={(option) => `${option.name} (${option.mmsi})`}
      isOptionEqualToValue={(option, value) => option.mmsi === value.mmsi}
      filterOptions={filterOptions}
      inputValue={searchValue}
      PopperComponent={(props) => {
        return <Popper {...props} container={mapRef.current} />;
      }}
      onInputChange={(_event, newInputValue) => setSearchValue(newInputValue)}
      value={selectedVessel}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option) => (
        <li {...props} key={option.mmsi + option.eni + option.name}>
          <Grid
            container
            display="grid"
            alignItems="center"
            sx={{
              gridTemplateColumns: "30px 1fr min-content",
              pl: 2,
              pr: 2,
              pt: 0,
            }}
          >
            <Grid>
              <img
                src={shipIcon}
                alt="ship"
                style={{ height: 20, transform: "rotate(90deg)" }}
              />
            </Grid>
            <Grid>
              {option.name}{" "}
              <i style={{ fontSize: 12 }}>
                <b>eni:</b> {option.eni}
              </i>
            </Grid>
            <Grid>
              <LocationSearching size="small" sx={{ fontSize: 14 }} />
            </Grid>
          </Grid>
        </li>
      )}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder="&#x1F50D; mmsi, eni or name"
          variant="filled"
          fullWidth
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
      onChange={(event, newValue) => {
        setSelectedVessel(newValue);
        if (newValue) {
          setCenter([newValue.lat, newValue.lon]);
          setZoom(12);
        } else {
          setCenter(null);
        }
      }}
      clearOnEscape
    />
  );
};

export default VesselAutocomplete;
