import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

function OutlinedSection(props) {
  return (
    <Box
      sx={{
        boxShadow: 2,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        position: "relative",
        margin: 1,
        marginTop: 2,
        marginBottom: 2,
        ...(props.containerStyle || {}),
        ...(props.sx || {}),
      }}
    >
      <Typography
        variant="body1"
        color="primary"
        component="div"
        fontWeight="bold"
        sx={{
          position: "absolute",
          top: -14,
          left: 10,
          bgcolor: "background.paper",
          px: 1,
          ...(props.titleStyle || {}),
        }}
      >
        {props.title}
      </Typography>
      <Box p={1.4}>{props.children}</Box>
    </Box>
  );
}

export default OutlinedSection;

OutlinedSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.any,
  titleStyle: PropTypes.any,
};
