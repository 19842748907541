import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import Flag from "react-world-flags";
import ProgressGrid from "./ProgressGrid";

function valuetext(epoch) {
  if (!epoch) return "?";
  const date = new Date(epoch * 1000);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const VesselDetails = ({
  vessel,
  vesselData,
  progress,
  loading,
  handleOpenChange,
  error,
  handleAddToFleetModal,
  handleTrack,
}) => (
  <Card
    elevation={0}
    sx={{
      backgroundColor: "transparent",
      borderRadius: 0,
      boxShadow: "none",
    }}
  >
    {vessel.fromCache && (
      <Alert
        severity="warning"
        sx={{
          padding: 0,
          pl: 1,
          mb: 1,
          height: "36px",
          fontSize: "0.75rem",
          backgroundColor: "transparent",
          color: "#ffb433",
          fontWeight: 800,
        }}
      >
        OFF RADAR: data from last known position
      </Alert>
    )}
    <Grid container gap={2} alignItems="center" flexWrap="nowrap">
      <Flag
        code={vessel.country_iso}
        style={{ width: "45px", height: "50px" }}
      />
      <Grid item>
        <Typography variant="h6" component="div" lineHeight={1}>
          {vessel.name || "?"}
        </Typography>
        <Typography variant="body2" component="div" lineHeight={1}>
          {vessel.type_specific || "?"}
        </Typography>
      </Grid>
    </Grid>
    <CardContent>
      <Grid container gap={1}>
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography
              variant="subtitle2"
              lineHeight={1}
              color="text.secondary"
            >
              {vesselData.dep_port || "?"}
            </Typography>
            <Typography variant="subtitle1" lineHeight={1}>
              {vesselData.dep_port_unlocode || "?"}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="subtitle2"
              lineHeight={1}
              color="text.secondary"
              textAlign="right"
            >
              {vesselData.dest_port || "?"}
            </Typography>
            <Typography variant="subtitle1" lineHeight={1}>
              {vesselData.dest_port_unlocode}
            </Typography>
          </Grid>
        </Grid>
        <ProgressGrid value={progress} />
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              ATD
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {valuetext(vesselData.atd_epoch)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
                textAlign: "right",
              }}
            >
              ETA
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {valuetext(vesselData.eta_epoch)}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid
          container
          display="grid"
          sx={{ gridTemplateColumns: "85px 1fr min-content" }}
          gap={1}
        >
          <Grid item>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              Status
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vesselData.navigation_status || "?"}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              Speed/Course
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vessel.speed || "?"}kn / {vessel.course || "?"}°
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              Draft
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vesselData.draft || "?"}m
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              Product
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vessel.product || "?"}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              Length
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vessel.length ?? "?"}m
            </Typography>
          </Grid>
          <Grid>
            <Typography
              variant="overline"
              component="p"
              lineHeight={1}
              sx={{
                margin: "0 !important",
              }}
            >
              DWT
            </Typography>
            <Typography variant="caption" lineHeight={1} color="text.secondary">
              {vessel.deadweight ?? "?"}t
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "36px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && (
        <Grid
          container
          display="grid"
          sx={{
            mt: 2,
            gap: 1,
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <Button
            onClick={() => handleAddToFleetModal(vessel)}
            variant="outlined"
          >
            + Fleet
          </Button>
          <Button onClick={() => handleTrack(vessel)} variant="outlined">
            Track
          </Button>
          <Button
            disabled={loading}
            onClick={handleOpenChange}
            variant="contained"
          >
            Details
          </Button>
        </Grid>
      )}
    </CardContent>
  </Card>
);

export default React.memo(VesselDetails);
