import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { UserContext } from "../../AuthGuard";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingPost, useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import CheckboxListWithToggle from "../../trading/atoms/CheckboxListWithToggle";

const Settings = ({ isNotVerified }) => {
  const [user, setUser] = useContext(UserContext);
  const { t } = useTranslation();
  const [acceptedCharterers, setAcceptedCharterers] = useState(user?.acceptedCharterers || []);
  const [notifications, setNotifications] = useState(user?.notifications || { phone: false, email: false });
  const [chartererOptions, setChartererOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const post = useTradingPost();
  const get = useTradingGet();

  useEffect(() => {
    const fetchChartererOptions = async () => {
      try {
        const res = await get("companies-by-type", { type: "oil" });
        if (res && !("error" in res)) {
          setChartererOptions(res.companies);
        }
      } catch (error) {
        console.error("Failed to fetch charterer options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChartererOptions();
  }, []);

  const handleAcceptedCharterersChange = (updatedCharterers) => {
    setAcceptedCharterers(updatedCharterers);
    saveSettings({ acceptedCharterers: updatedCharterers });
  };

  const handleNotificationChange = (event) => {
    const { name, checked } = event.target;
    setNotifications((prev) => ({ ...prev, [name]: checked }));
    saveSettings({ notifications: { ...notifications, [name]: checked } });
  };

  const saveSettings = async (settings) => {
    try {
      await post("update-shipping-settings", settings);
      setUser((prevUser) => ({
        ...prevUser,
        ...settings,
      }));
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  if (loading) {
    return (
      <SpotbargeLayout title={t("trading_settings")} isNotVerified={isNotVerified}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </SpotbargeLayout>
    );
  }

  return (
    <SpotbargeLayout title={t("trading_settings")} isNotVerified={isNotVerified}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <OutlinedSection title={`1. ${t("trading_settings_accepted_charterers")}`}>
              <CheckboxListWithToggle
                options={chartererOptions}
                selectedOptions={acceptedCharterers}
                onChange={handleAcceptedCharterersChange}
                title={t("trading_settings_accepted_charterers")}
              />
            </OutlinedSection>
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedSection title={`2. ${t("trading_settings_notifications")}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifications.phone}
                    onChange={handleNotificationChange}
                    name="phone"
                  />
                }
                label={t("trading_settings_phone")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifications.email}
                    onChange={handleNotificationChange}
                    name="email"
                  />
                }
                label={t("trading_settings_email")}
              />
            </OutlinedSection>
          </Grid>
        </Grid>
      </Paper>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(Settings, {
  fallback: <GeneralErrorPage />,
});
