import { memo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { ImportExport } from "@mui/icons-material";

const FleetDialog = ({
  open,
  onClose,
  isFleetUpdate,
  fleetToUpsert,
  setFleetToUpsert,
  addFleet,
  updateFleet,
  mapRef,
}) => {
  const [loading, setLoading] = useState(false);
  const [addingShared, setAddingShared] = useState(false);
  const [fleetCode, setFleetCode] = useState("");

  const constSwitchAddTypes = () => {
    setAddingShared((prev) => !prev);
  };

  return (
    <Dialog
      container={mapRef.current}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 9999999999999,
      }}
    >
      <DialogTitle>
        <Grid>{isFleetUpdate ? "Update Fleet" : "Add new fleet"}</Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          maxWidth: 400,
        }}
      >
        {!isFleetUpdate && (
          <Button
            fullWidth
            sx={{ mb: 2 }}
            onClick={constSwitchAddTypes}
            variant="contained"
            startIcon={
              <ImportExport fontSize="small" sx={{ fontSize: "15px" }} />
            }
          >
            {!addingShared ? "Add shared fleet" : "Add manual fleet"}
          </Button>
        )}
        {addingShared && (
          <>
            <DialogContentText>
              Provide the shared fleet code to add the fleet to your list.
            </DialogContentText>
            <Grid>
              <TextField
                fullWidth
                maxRows={6}
                minRows={6}
                sx={{ mt: 2 }}
                multiline
                value={fleetCode}
                placeholder="Paste fleet code here"
                onChange={(e) => {
                  setFleetCode(e.target.value);
                }}
              ></TextField>
            </Grid>
          </>
        )}
        {!addingShared && (
          <>
            <DialogContentText>
              Provide the name and color of the fleet you want to{" "}
              {isFleetUpdate ? "update" : "add"}.
            </DialogContentText>
            <Grid container gap={2} mt={2}>
              <TextField
                label="Name"
                fullWidth
                value={fleetToUpsert.name}
                placeholder="Fleet name"
                onChange={(e) => {
                  setFleetToUpsert((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
              <Grid
                container
                gap={6}
                sx={{
                  alignItems: "center",
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                }}
              >
                <HexColorPicker
                  color={fleetToUpsert.color}
                  onChange={(value) => {
                    setFleetToUpsert((prev) => ({
                      ...prev,
                      color: value,
                    }));
                  }}
                />
                <svg
                  width="80"
                  height="110"
                  viewBox="0 0 4 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7L2 0L4 7L2 5.5L0 7Z"
                    fill={fleetToUpsert.color || "red"}
                  />
                </svg>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            !addingShared
              ? !fleetToUpsert.name || loading
              : !fleetCode || loading
          }
          onClick={async (e) => {
            setLoading(true);
            isFleetUpdate ? await updateFleet() : await addFleet(fleetCode);
            setLoading(false);
            setFleetCode("");
            setAddingShared(false);
            setLoading(false);
          }}
          color="success"
        >
          {!loading && (isFleetUpdate ? "Update" : "Add")}
          {loading && <CircularProgress size={10} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(FleetDialog);
