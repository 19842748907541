import React, {
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "firebase/compat/auth";
import SpotbargeTable from "../../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingGet } from "../../../request";
import { DateTime } from "luxon";

const YourCargos = () => {
  const { t } = useTranslation();
  const [cargos, setCargos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const get = useTradingGet();

  useEffect(() => {
    const fetchCargos = async () => {
      try {
        const result = await get("your-cargos");
        if (result && !result.error) {
          const formattedCargos = result.map((cargo) => ({
            ...cargo,
            loadStart: DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd").toFormat("dd/MM/yyyy"),
            loadEnd: DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd").toFormat("dd/MM/yyyy"),
          }));
          setCargos(formattedCargos);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchCargos();
  }, []);

  const columns = useMemo(
    () => [
      { id: "loadStart", label: t("trading_your_cargos_load_start") },
      { id: "loadEnd", label: t("trading_your_cargos_load_end") },
      { id: "loadTerminal", label: t("trading_your_cargos_load_terminal") },
      { id: "extraLoadTerminal", label: t("trading_your_cargos_extra_load_terminal") },
      { id: "dischargeTerminal", label: t("trading_your_cargos_discharge_terminal") },
      { id: "extraDischargeTerminal", label: t("trading_your_cargos_extra_discharge_terminal") },
      { id: "product", label: t("trading_your_cargos_product") },
      { id: "volume", label: t("trading_your_cargos_volume") },
      { id: "type", label: t("trading_your_cargos_type") },
      { id: "area", label: t("trading_your_cargos_area") },
      { id: "status", label: t("trading_your_cargos_status") },
      { id: "user", label: t("trading_your_cargos_user") },
      {
        id: "info",
        label: "",
      },
    ],
    [t]
  );

  const columnRenderMap = {
    info: (row) => (
      <IconButton>
        <InfoIcon />
      </IconButton>
    ),
  };

  return (
    <SpotbargeLayout title={t("trading_your_cargos")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <SpotbargeTable
            data={cargos}
            columns={columns}
            columnRenderMap={columnRenderMap}
          />
        )}
      </Box>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(YourCargos, {
  fallback: <GeneralErrorPage />,
});
