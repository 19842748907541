import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Marker, Popup, Tooltip, CircleMarker } from "react-leaflet";
import { divIcon } from "leaflet";
import { Grid } from "@mui/material";
import isEqual from "lodash/isEqual";
import { useVesselGet } from "../../../../request";
import VesselDetails from "./details/VesselDetails";
import VesselInfoSlide from "../VesselInfoSlide";
import { calculateProgress } from "../../../../utils";

const VesselPopup = React.memo(
  ({
    vessel,
    vesselData,
    progress,
    loading,
    handleOpenChange,
    error,
    handleAddToFleetModal,
    handleTrack,
  }) => (
    <Popup>
      <VesselDetails
        vessel={vessel}
        vesselData={vesselData}
        progress={progress}
        loading={loading}
        handleOpenChange={handleOpenChange}
        error={error}
        handleAddToFleetModal={handleAddToFleetModal}
        handleTrack={handleTrack}
      />
    </Popup>
  ),
);

const VesselTooltip = React.memo(({ vessel }) => (
  <Tooltip direction="top" opacity={0.9}>
    <Grid>
      <b>
        {vessel.name} [{vessel.country_iso}]
      </b>
    </Grid>
    <Grid>
      Destination: <b>{vessel.destination}</b>
    </Grid>
  </Tooltip>
));

const BarginV2 = ({
  vessel,
  selected,
  selectVessel,
  handleAddToFleetModal,
  color,
  handleTrack,
}) => {
  const [vesselData, setVesselData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const get = useVesselGet();
  const circleMarkerRef = useRef(null);
  const markerRef = useRef(null);

  const handleOpenChange = useCallback(() => {
    setOpen(true);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await get("vessel", {
        mmsi: vessel.mmsi,
      });
      setVesselData({ ...response, ...vessel });
    } catch (error) {
      setError("Error fetching some vessel data");
      console.error("Error fetching vessel data:", error);
    }
    setLoading(false);
  }, [get, vessel]);

  const progress = useMemo(() => {
    if (!vesselData.atd_epoch || !vesselData.eta_epoch) {
      return 0;
    }
    return calculateProgress(vesselData.atd_epoch, vesselData.eta_epoch);
  }, [vesselData.atd_epoch, vesselData.eta_epoch]);

  const handleSelectVessel = useCallback(
    (vessel) => {
      selectVessel(vessel);
      setPopupOpen(true);
    },
    [selectVessel],
  );

  const markerEvents = useMemo(
    () => ({
      click: () => {
        handleSelectVessel(vessel);
        fetchData();
      },
      popupclose: () => {
        handleSelectVessel(null);
        setOpen(false);
        setPopupOpen(false);
      },
    }),
    [handleSelectVessel, fetchData, vessel],
  );

  useEffect(() => {
    if (selected && circleMarkerRef.current) {
      circleMarkerRef.current.bringToFront();
    }
  }, [selected]);

  useEffect(() => {
    if (popupOpen && markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [popupOpen]);

  const renderCircleMarker = useMemo(
    () => (
      <CircleMarker
        ref={circleMarkerRef}
        center={[vessel.lat, vessel.lon]}
        radius={5}
        color="black"
        weight={0.4}
        fillColor={color ?? "#fba33f"}
        stroke="black"
        fillOpacity={vessel.fromCache ? 0.6 : 1}
        eventHandlers={{
          click: () => {
            handleSelectVessel(vessel);
            fetchData();
          },
        }}
        key={color + vessel.lat + vessel.lon + vessel.fromCache}
      >
        <VesselTooltip vessel={vessel} />
      </CircleMarker>
    ),
    [vessel, color, handleSelectVessel, fetchData],
  );

  const renderMarker = useMemo(
    () => (
      <Marker
        ref={markerRef}
        position={[vessel.lat, vessel.lon]}
        opacity={vessel.fromCache ? 0.6 : 1}
        rotationAngle={
          (vessel.heading && vessel.heading !== 511
            ? vessel.heading
            : vessel.course) % 360
        }
        eventHandlers={markerEvents}
        icon={divIcon({
          className: selected ? "mymarker selected-marker" : "mymarker",
          html:
            vessel.speed <= 0.4
              ? `<svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="4.5" fill="${
                  color ?? "#fba33f"
                }" stroke="black" stroke-width="0.4"/>
              </svg>`
              : `<svg
                width="22"
                height="22"
                viewBox="0 0 4 7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 7L2 0L4 7L2 5.5L0 7Z" fill="${
                  color ?? "#fba33f"
                }" stroke="black" stroke-width="0.15"/>
              </svg>`,
          iconAnchor: [11, 11],
        })}
      >
        <VesselPopup
          vessel={vessel}
          vesselData={vesselData}
          progress={progress}
          loading={loading}
          handleOpenChange={handleOpenChange}
          error={error}
          handleAddToFleetModal={handleAddToFleetModal}
          handleTrack={handleTrack}
        />
        <VesselTooltip vessel={vessel} />
        {open && <VesselInfoSlide open={open} vesselData={vesselData} />}
      </Marker>
    ),
    [
      vessel,
      color,
      selected,
      markerEvents,
      vesselData,
      progress,
      loading,
      handleOpenChange,
      error,
      handleAddToFleetModal,
      handleTrack,
      open,
    ],
  );

  return vessel.speed <= 0.4 && !selected ? renderCircleMarker : renderMarker;
};

export default React.memo(BarginV2, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.vessel, nextProps.vessel) &&
    prevProps.color === nextProps.color &&
    prevProps.selected === nextProps.selected
  );
});
