import { memo } from "react";
import { LayersControl, LayerGroup } from "react-leaflet";
import isEqual from "lodash/isEqual";
import BarginV2 from "./BarginV2";

const Bargins = ({
  vessels,
  selectedVessel,
  selectVessel,
  handleAddToFleetModal,
  fleetColor,
  handleTrack,
  hidden,
}) => {
  return (
    <LayersControl.Overlay checked={!hidden} name="Show barges">
      <LayerGroup>
        {vessels.map((vessel, index) => (
          <BarginV2
            key={vessel.mmsi + vessel.imo + vessel.name + index}
            vessel={vessel}
            selected={vessel.mmsi === selectedVessel?.mmsi}
            selectVessel={selectVessel}
            handleAddToFleetModal={handleAddToFleetModal}
            fleetColor={fleetColor}
            color={fleetColor[vessel.mmsi]}
            handleTrack={handleTrack}
          />
        ))}
      </LayerGroup>
    </LayersControl.Overlay>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.vessels, nextProps.vessels) &&
    prevProps.selectedVessel?.mmsi === nextProps.selectedVessel?.mmsi &&
    isEqual(prevProps.fleetColor, nextProps.fleetColor) &&
    prevProps.hidden === nextProps.hidden
  );
};

export default memo(Bargins, areEqual);
