import { capitalize } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
if (process.env.REACT_APP_ENV === "local") {
  firebase.storage().useEmulator("localhost", 9199);
}
const db = getFirestore();
if (process.env.REACT_APP_ENV === "local") {
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

export function getDb() {
  return db;
}

export const callFunction = async (
  functionName,
  data = {},
  isPost = false,
  headers = {},
  responseType = "json",
  moduleName = "",
  isGet = false,
) => {
  try {
    const isProd =
      process.env.NODE_ENV && process.env.NODE_ENV === "production";
    const user = firebase.auth().currentUser;
    const token = user && (await user.getIdToken());
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };

    if (isGet) {
      options = {
        method: "GET",
        headers: headers,
      };
      const queryParams = new URLSearchParams(data).toString();
      functionName = `${functionName}?${queryParams}`;
    }

    const endpoint = isPost === null ? "" : isPost ? "write" : "get";
    const functionPath = endpoint ? (moduleName ? moduleName + capitalize(endpoint) : endpoint) : "";
    functionName = `${functionPath}${functionPath ? "/" : ""}${functionName}`;
    const url = isProd
      ? `/${functionName}`
      : `http://127.0.0.1:5001/spotbarge-dev/europe-west1/${functionPath}${functionPath ? "/" : ""}${functionName}`;
    const response = await fetch(url, options);
    if (responseType === "blob") {
      return await response.blob();
    }
    return await response.json();
  } catch (e) {
    return false;
  }
};
