import { useMemo, useEffect, useState, useRef } from "react";
import { Polyline, Marker, LayerGroup, Tooltip } from "react-leaflet";
import { Grid } from "@mui/material";
import { divIcon } from "leaflet";
import { useVesselGet } from "../../../request";
import HistoryPlayer from "./HistoryPlayer";
import { useMap } from "react-leaflet";

const VesselHistory = ({ selectedVessel, clear, setLoading }) => {
  const [points, setPoints] = useState([]);
  const [playerPos, setPlayerPos] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(200);
  const [vessel, setVessel] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [hasFinished, setHasFinished] = useState(false);
  const [filterDays, setFilterDays] = useState(1);
  const [speed, setSpeed] = useState(80000);
  const markerRef = useRef(null);
  const get = useVesselGet();
  const map = useMap();
  const coordinates = useMemo(
    () => points.map((pos) => [pos.lat, pos.lon]),
    [points],
  );

  function valuetext(epoch) {
    const date = new Date(epoch * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const handlePlayerPosChange = (value) => {
    setPlayerPos(value);
    const foundPoint = points.find((point) => {
      return (
        Math.floor(point.last_position_epoch / 100) === Math.floor(value / 100)
      );
    });
    setVessel((prev) => foundPoint || prev);
    if (foundPoint) {
      // setLocalCenter([foundPoint.lat, foundPoint.lon]);
      markerRef.current.setRotationAngle(foundPoint?.course);
    }
  };

  const handlePlayPause = () => {
    if (isFirstCall || hasFinished) {
      setIsFirstCall(false);
      setHasFinished(false);
      handlePlayerPosChange(min);
    }
    if (isPlaying) {
      clearInterval(intervalId);
      setIsPlaying(false);
    } else {
      const totalSteps = (max - min) / 100;
      const intervalDuration = (100000 - speed) / totalSteps;
      const id = setInterval(() => {
        setPlayerPos((prevPos) => {
          const newPos = prevPos + 100;
          handlePlayerPosChange(newPos);
          if (newPos >= max) {
            clearInterval(id);
            setIsPlaying(false);
            setHasFinished(true);
            return max;
          }
          return newPos;
        });
      }, intervalDuration);
      setIntervalId(id);
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (playerPos) {
      map.setView([vessel.lat, vessel.lon]);
    }
  }, [playerPos]);

  useEffect(() => {
    const first = points[0];
    setVessel(first);
    if (points && points.length > 0) {
      const epochs = points.map((point) => point.last_position_epoch);
      const minEpoch = Math.min(...epochs);
      const maxEpoch = Math.max(...epochs);
      setMin(minEpoch);
      setMax(maxEpoch);
      setPlayerPos(maxEpoch);
    }
  }, [points]);

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const handleFilterChange = async (days) => {
    setFilterDays(days);
    setIsFirstCall(true);
    await fetchVesselData(days);
  };

  const fetchVesselData = async (days = 1) => {
    try {
      setLoading(true);
      const response = await get("vessel-track", {
        ...selectedVessel,
        days: days,
      });
      if (response.length >= 0) {
        setPoints(response);
        setVessel(response[0]);
      } else {
        setPoints([]);
        setVessel(null);
      }
    } catch (error) {
      setPoints([]);
      setVessel(null);
      console.error("Error fetching vessel data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedVessel) {
      fetchVesselData();
    }
  }, [selectedVessel]);

  if (!vessel) return null;

  return (
    <>
      <LayerGroup>
        <Marker
          ref={markerRef}
          position={[vessel.lat, vessel.lon]}
          rotationAngle={vessel.course}
          icon={divIcon({
            className: "mymarker",
            html:
              vessel.speed === 0
                ? `<svg
               width="22"
               height="22"
               viewBox="0 0 22 22"
               xmlns="http://www.w3.org/2000/svg"
             >
               <circle cx="11" cy="11" r="5" fill="#fba33f" stroke="black" stroke-width="0.7"/>
             </svg>`
                : `<svg
               width="22"
               height="22"
               viewBox="0 0 4 7"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path d="M0 7L2 0L4 7L2 5.5L0 7Z" fill="#fba33f" stroke="black" stroke-width="0.2"/>
             </svg>`,
            iconAnchor: [11, 11],
          })}
        >
          <Tooltip
            direction="right"
            opacity={0.9}
            permanent
            className="track-popup"
          >
            <Grid
              sx={{
                textAlign: "center",
              }}
            >
              <b>{valuetext(vessel.last_position_epoch)}</b>
            </Grid>
            <Grid>
              <b>
                {selectedVessel.name} [{selectedVessel.country_iso}]
              </b>
            </Grid>
            <Grid>
              Destination: <b>{vessel.destination}</b>
            </Grid>
            <Grid>
              Speed: <b>{vessel.speed}kn</b>
            </Grid>
          </Tooltip>
        </Marker>
        <Polyline
          style={{ backgroundColor: "red" }}
          pathOptions={{ weight: 3, stroke: true }}
          positions={coordinates}
        />
      </LayerGroup>
      <HistoryPlayer
        vessel={points}
        clear={clear}
        min={min}
        setMin={setMin}
        max={max}
        setMax={setMax}
        playerPos={playerPos}
        setPlayerPos={handlePlayerPosChange}
        handlePlayPause={handlePlayPause}
        isPlaying={isPlaying}
        speed={speed}
        setSpeed={setSpeed}
        filterDays={filterDays}
        setFilterDays={handleFilterChange}
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return true;
};

export default VesselHistory;
