import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, List, ListItem } from "@mui/material";

const CheckboxListWithToggle = ({ options, selectedOptions, onChange, title }) => {
  const allSelected = selectedOptions.length === options.length;

  const handleToggleAll = () => {
    const updatedOptions = allSelected ? [] : [...options];
    onChange(updatedOptions);
  };

  const handleOptionChange = (event) => {
    const { value, checked } = event.target;
    const updatedOptions = checked
      ? [...selectedOptions, value]
      : selectedOptions.filter((option) => option !== value);
    onChange(updatedOptions);
  };

  return (
    <Box>
      <Box sx={{ position: 'absolute', right: "2rem", top: ".3rem", zIndex: 1000 }}>
        <Button onClick={handleToggleAll} size="small">
          {allSelected ? 'Uncheck All' : 'Check All'}
        </Button>
      </Box>
      <List sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        {options.map((option) => (
          <ListItem key={option} dense disablePadding>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={handleOptionChange}
                  value={option}
                />
              }
              label={option}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CheckboxListWithToggle;
