import * as React from "react";
import {
  Button,
  ButtonGroup,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
  Switch,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useEffect, useState } from "react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { Delete, Edit, Book, Cancel } from "@mui/icons-material";
import SpotbargeTable from "../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";
import { useGet, usePost } from "../../request";

function AdminUser() {
  const { t } = useTranslation();

  const [filter, setFilter] = useState("All");

  const get = useGet();
  const post = usePost();

  const columns = [
    {
      id: "firstName",
      label: t("adminuser_first_name"),
    },
    {
      id: "lastName",
      label: t("adminuser_last_name"),
    },
    {
      id: "companyName",
      label: t("adminuser_company_name"),
      filter: "checkboxes",
    },
    {
      id: "email",
      label: t("menu_admin_email"),
    },
    {
      id: "companyType",
      label: t("adminuser_company_type"),
      filter: "checkboxes",
    },
    {
      id: "bargeNames",
      label: t("adminuser_barge_names"),
    },
    {
      id: "phone",
      label: t("adminuser_phone"),
    },
    {
      id: "country",
      label: t("adminuser_country"),
    },
    {
      id: "city",
      label: t("adminuser_city"),
      numeric: true,
    },
    {
      id: "role",
      label: t("adminuser_role"),
      filter: "checkboxes",
    },
    {
      id: "features",
      label: "Features",
    },
    {
      id: "actions",
      label: t("adminuser_actions"),
    },
  ];
  const filterRows = (rows) => {
    if (filter === "All") return rows;
    return rows.filter((row) => row.role === filter);
  };
  const handleFilterChange = (event) => {
    setFilter(event.currentTarget.dataset.filter);
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const filteredData = filterRows(data);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async () => {
      try {
        let result = await get("admin-users", {});
        if (!result) {
          setError(t("adminuser_something_went_wrong"));
          return;
        }
        if (result.error) {
          setError(result.error);
          return;
        }
        setData(result);
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleDelete = async (row) => {
    let result = await post("admin-delete-user", { id: row.id });
    if (!result) {
      setError(t("adminuser_something_went_wrong"));
      return;
    }
    if (result.error) {
      setError(result.error);
      return;
    }
    if (result.success) {
      setData(data.filter((r) => r.id !== row.id));
    }
  };

  const handleChangeRole = async (row, role) => {
    let result = await post("admin-update-user", {
      id: row.id,
      role,
    });
    if (result.success) {
      setData(
        data.map((r) => {
          if (r.id === row.id) {
            return {
              ...r,
              role,
            };
          }
          return r;
        }),
      );
    }
  };

  const updateFeatureFlags = async (row, features) => {
    await post("admin-update-feature-flag", {
      id: row.id,
      features,
    });
  };

  const handleChangeFeatureFlag = async (row, feature, enabled) => {
    setData(oldData =>
      oldData.map((r) => {
        if (r.id === row.id) {
          const updatedFeatures = enabled
            ? [...(r.features || []), feature]
            : (r.features || []).filter((f) => f !== feature);
          updateFeatureFlags(r, updatedFeatures);
          return {
            ...r,
            features: updatedFeatures,
          };
        }
        return r;
      }),
    );
  };

  const renderActions = (row) => (
    <>
      <IconButton color="error" onClick={() => handleDelete(row)}>
        <Tooltip title={t("adminuser_delete_user")}>
          <Delete />
        </Tooltip>
      </IconButton>
      <IconButton
        color="primary"
        onClick={() => handleChangeRole(row, "write")}
      >
        <Tooltip title={t("adminuser_change_role_write")}>
          <Edit />
        </Tooltip>
      </IconButton>
      <IconButton color="warning" onClick={() => handleChangeRole(row, "read")}>
        <Tooltip title={t("adminuser_change_role_read")}>
          <Book />
        </Tooltip>
      </IconButton>
      <IconButton
        color="secundary"
        onClick={() => handleChangeRole(row, "none")}
      >
        <Tooltip title={t("adminuser_change_role_none")}>
          <Cancel />
        </Tooltip>
      </IconButton>
    </>
  );

  const renderFeatures = (row) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={row.features?.includes("map") || false}
            onChange={(event) => handleChangeFeatureFlag(row, "map", event.target.checked)}
          />
        }
        label="Map"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={row.features?.includes("trading") || false}
            onChange={(event) => handleChangeFeatureFlag(row, "trading", event.target.checked)}
          />
        }
        label="Trading"
      />
    </div>
  );

  const renderBargeNames = (row) => (
    <ul>
      {(row.bargeNames || []).map((name, index) => (
        <li key={index}>{name}</li>
      ))}
    </ul>
  );

  const columnRenderMap = {
    features: renderFeatures,
    actions: renderActions,
    bargeNames: renderBargeNames,
  };

  return (
    <SpotbargeLayout title={t("adminuser_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <>
            <ButtonGroup variant="outlined" sx={{ mb: 2 }}>
              <Button
                onClick={handleFilterChange}
                variant={filter === "All" ? "contained" : "outlined"}
                data-filter="All"
              >
                {t("adminuser_all")}
              </Button>
              <Button
                onClick={handleFilterChange}
                variant={filter === "admin" ? "contained" : "outlined"}
                data-filter="admin"
              >
                {t("adminuser_admin")}
              </Button>
              <Button
                onClick={handleFilterChange}
                variant={filter === "write" ? "contained" : "outlined"}
                data-filter="write"
              >
                {t("adminuser_write")}
              </Button>
              <Button
                onClick={handleFilterChange}
                variant={filter === "read" ? "contained" : "outlined"}
                data-filter="read"
              >
                {t("adminuser_read")}
              </Button>
              <Button
                onClick={handleFilterChange}
                variant={filter === "none" ? "contained" : "outlined"}
                data-filter="none"
              >
                {t("adminuser_none")}
              </Button>
            </ButtonGroup>
            <SpotbargeTable
              data={filteredData}
              columns={columns}
              columnRenderMap={columnRenderMap}
              paginate={filteredData.length > 25}
              filterKey="adminUser"
              minH="82vh"
            />
          </>
        )}
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(AdminUser, {
  fallback: <GeneralErrorPage />,
});
