import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Alert,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";

const FindCargo = () => {
  const { t } = useTranslation();
  const [barges, setBarges] = useState([]);
  const [selectedBarge, setSelectedBarge] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const get = useTradingGet();

  useEffect(() => {
    const fetchBarges = async () => {
      try {
        const result = await get("your-barges");
        if (result && !result.error) {
          setBarges(result);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchBarges();
  }, []);

  const handleBargeChange = (event) => {
    setSelectedBarge(event.target.value);
  };

  return (
    <SpotbargeLayout title={t("Filter your search")}>
      <Box sx={{ p: 3 }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OutlinedSection title={`1. ${t("Select from your barges")}`}>
                <Select
                  fullWidth
                  value={selectedBarge}
                  onChange={handleBargeChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {t("Select a barge")}
                  </MenuItem>
                  {barges.map((barge) => (
                    <MenuItem key={barge.name} value={barge.name}>
                      {`Barge: ${barge.name}, ${barge.size} m3, pre cargo: ${barge.preCargo1} / ${barge.preCargo2} / ${barge.preCargo3}, ${barge.loadDate}, ${barge.area}`}
                    </MenuItem>
                  ))}
                </Select>
              </OutlinedSection>
            </Grid>
            <Grid item xs={12} md={6}>
              <OutlinedSection title={`2. ${t("Flexibility dates")}`}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="1 day"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="2 days"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="3 days"
                />
              </OutlinedSection>
            </Grid>
            <Grid item xs={12} md={6}>
              <OutlinedSection title={`3. ${t("Flexibility tons")}`}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="< 500"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="500 - 1000"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="1000 - 3000"
                />
              </OutlinedSection>
            </Grid>
          </Grid>
        )}
      </Box>
    </SpotbargeLayout>
  );
};

export default FindCargo;
