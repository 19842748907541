import React, { useContext, useState } from "react";
import { UserContext } from "../AuthGuard";
import TradingOil from "./oil/TradingOil";
import TradingShipping from "./shipping/TradingShipping";
import { IconButton, Box } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

const Trading = () => {
  const [ user ] = useContext(UserContext);
  const [showOil, setShowOil] = useState(user.companyType === "oil");

  const handleToggle = () => {
    setShowOil((prev) => !prev);
  };

  if (user.role === "admin") {
    return (
      <Box position="relative">
        <IconButton 
          onClick={handleToggle} 
          color={showOil ? "primary" : "default"} 
          sx={{ position: "absolute", right: 0 }}
        >
          <SwapHorizIcon />
        </IconButton>
        {showOil ? <TradingOil /> : <TradingShipping />}
      </Box>
    );
  }

  return user.companyType === "oil" ? <TradingOil /> : <TradingShipping />;
};

export default Trading;
