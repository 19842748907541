import { useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Slider,
  IconButton,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import SpeedIcon from "@mui/icons-material/Speed";
import { useMap } from "react-leaflet";

const StyledPlayer = styled(Paper)(({ theme }) => ({
  minHeight: "50px",
  width: "500px",
  zIndex: 999999999,
  position: "absolute",
  bottom: 20,
  right: "calc(50% - 250px)",
  padding: 16,
  borderRadius: 16,
  maxWidth: "100%",
  backgroundColor: "rgba(255,255,255,0.6)",
  backdropFilter: "blur(10px)",
  zoom: 0.8,
  paddingTop: 0,
  [theme.breakpoints.down("sm")]: {
    zoom: 0.7,
    bottom: 75,
  },
}));

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const HistoryPlayer = ({
  vessel,
  clear,
  min,
  max,
  playerPos,
  setPlayerPos,
  handlePlayPause,
  isPlaying,
  speed,
  setSpeed,
  filterDays,
  setFilterDays,
}) => {
  const map = useMap();

  function valuetext(epoch) {
    const date = new Date(epoch * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  return (
    <StyledPlayer
      elevation={12}
      onMouseEnter={() => {
        map.dragging.disable();
        map.doubleClickZoom.disable();
      }}
      onMouseLeave={() => {
        map.dragging.enable();
        map.doubleClickZoom.enable();
      }}
    >
      <Grid
        sx={{
          display: "grid",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Button
          sx={{
            marginBottom: "30px",
          }}
          onClick={() => {
            map.dragging.enable();
            map.doubleClickZoom.enable();
            clear();
          }}
        >
          Close
        </Button>
        <Slider
          size="small"
          valueLabelDisplay="on"
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          value={playerPos}
          min={min}
          step={100}
          max={max}
          onChange={(e, value) => {
            setPlayerPos(value);
          }}
          sx={(t) => ({
            color: "rgba(0,0,0,0.87)",
            height: 6,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
              transition: "0.1s cubic-bezier(.47,1.64,.41,.8)",
              "&::before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
            "& .MuiSlider-track": {
              transition: "0s cubic-bezier(.47,1.64,.41,.8)",
            },
          })}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: -2,
        }}
      >
        <TinyText>{valuetext(min)}</TinyText>
        <TinyText>{valuetext(max)}</TinyText>
      </Box>
      <Box
        sx={() => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: -1,
        })}
      >
        <IconButton
          onClick={() => {
            setPlayerPos(playerPos - 10000);
          }}
        >
          <FastRewindRounded fontSize="large" />
        </IconButton>
        <IconButton onClick={handlePlayPause}>
          {!isPlaying ? (
            <PlayArrowRounded sx={{ fontSize: "3rem" }} />
          ) : (
            <PauseRounded sx={{ fontSize: "3rem" }} />
          )}
        </IconButton>
        <IconButton
          onClick={() => {
            setPlayerPos(playerPos + 10000);
          }}
        >
          <FastForwardRounded fontSize="large" />
        </IconButton>
      </Box>
      <Stack
        spacing={2}
        direction="row"
        sx={(theme) => ({
          mb: 1,
          px: 8,
        })}
        alignItems="center"
      >
        Slow
        <SpeedIcon />
        <Slider
          value={speed}
          step={1000}
          min={1}
          max={100000}
          disabled={isPlaying}
          onChange={(_e, value) => setSpeed(value)}
          sx={() => ({
            color: "rgba(0,0,0,0.87)",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-thumb": {
              width: 24,
              height: 24,
              backgroundColor: "#fff",
              "&::before": {
                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "none",
              },
            },
          })}
        />
        <SpeedIcon />
        Fast
      </Stack>
      <ToggleButtonGroup
        fullWidth
        color="primary"
        value={filterDays}
        exclusive
        onChange={(event, value) => {
          if (value !== null) {
            setFilterDays(value);
          }
        }}
      >
        <ToggleButton value={1}>1 Day</ToggleButton>
        <ToggleButton value={3}>3 Days</ToggleButton>
        <ToggleButton value={7}>1 Week</ToggleButton>
      </ToggleButtonGroup>
    </StyledPlayer>
  );
};

export default HistoryPlayer;
