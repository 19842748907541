import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { UserContext } from "../../AuthGuard";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingPost, useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import CheckboxListWithToggle from "../../trading/atoms/CheckboxListWithToggle";

const Settings = ({ isNotVerified }) => {
  const [user, setUser] = useContext(UserContext);
  const { t } = useTranslation();
  const [selectedTOC, setSelectedTOC] = useState(user?.selectedTOC || "");
  const [notifications, setNotifications] = useState(user?.notifications || { phone: false, email: false });
  const [companyVisible, setCompanyVisible] = useState(user?.companyVisible || false);
  const [bargeAge, setBargeAge] = useState(user?.bargeAge || "");
  const [technicalOperators, setTechnicalOperators] = useState(user?.technicalOperators || []);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const post = useTradingPost();
  const get = useTradingGet();

  const TOCOptions = [
    "Shell GT&C's",
    "TTB",
    "BP VOY",
    "Exxon VOY",
    "Total BCG's"
  ];

  const bargeAgeOptions = ["<20", "<25", "<30", "N.A."];

  useEffect(() => {
    const fetchOperatorOptions = async () => {
      try {
        const res = await get("companies-by-type", { type: "shipping" });
        if (res && !("error" in res)) {
          setOperatorOptions(res.companies);
        }
      } catch (error) {
        console.error("Failed to fetch operator options:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOperatorOptions();
  }, []);

  const handleTOCChange = (event) => {
    setSelectedTOC(event.target.value);
    saveSettings({ selectedTOC: event.target.value });
  };

  const handleNotificationChange = (event) => {
    const { name, checked } = event.target;
    setNotifications((prev) => ({ ...prev, [name]: checked }));
    saveSettings({ notifications: { ...notifications, [name]: checked } });
  };

  const handleCompanyVisibleChange = (event) => {
    setCompanyVisible(event.target.checked);
    saveSettings({ companyVisible: event.target.checked });
  };

  const handleBargeAgeChange = (event) => {
    setBargeAge(event.target.value);
    saveSettings({ bargeAge: event.target.value });
  };

  const handleTechnicalOperatorChange = (updatedOperators) => {
    setTechnicalOperators(updatedOperators);
    saveSettings({ technicalOperators: updatedOperators });
  };

  const saveSettings = async (settings) => {
    try {
      await post("update-oil-settings", settings);
      setUser((prevUser) => ({
        ...prevUser,
        ...settings,
      }));
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  if (loading) {
    return (
      <SpotbargeLayout title={t("trading_settings")} isNotVerified={isNotVerified}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </SpotbargeLayout>
    );
  }

  return (
    <SpotbargeLayout title={t("trading_settings")} isNotVerified={isNotVerified}>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} flexWrap="wrap">
          <Box flex={1}>
            <OutlinedSection title={t("Terms & Conditions")} sx={{ mb: 3 }}>
              <RadioGroup
                value={selectedTOC}
                onChange={handleTOCChange}
              >
                {TOCOptions.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </OutlinedSection>
            <OutlinedSection title={t("Barge age")} sx={{ mb: 3 }}>
              <RadioGroup
                value={bargeAge}
                onChange={handleBargeAgeChange}
                row
              >
                {bargeAgeOptions.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={t(option)}
                  />
                ))}
              </RadioGroup>
            </OutlinedSection>
          </Box>
          <Box flex={1}>
            <OutlinedSection title={t("Visible to technical operators")} sx={{ mb: 3 }}>
              <CheckboxListWithToggle
                options={operatorOptions}
                selectedOptions={technicalOperators}
                onChange={handleTechnicalOperatorChange}
                title={t("Visible to technical operators")}
              />
            </OutlinedSection>
            <OutlinedSection title={t("Notifications")} sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifications.email}
                    onChange={handleNotificationChange}
                    name="email"
                  />
                }
                label={t("Email")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifications.phone}
                    onChange={handleNotificationChange}
                    name="phone"
                  />
                }
                label={t("Phone")}
              />
            </OutlinedSection>
            <OutlinedSection title={t("Company name visible")} sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={companyVisible}
                    onChange={handleCompanyVisibleChange}
                  />
                }
                label={t("Yes")}
              />
            </OutlinedSection>
          </Box>
        </Box>
      </Paper>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(Settings, {
  fallback: <GeneralErrorPage />,
});
