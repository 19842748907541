import React, { useState } from "react";
import { Grid2 as Grid, Tabs, Tab, Box } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../../GeneralErrorPage";
import Settings from "./Settings";
import EnterCargo from "./EnterCargo";
import YourCargos from "./YourCargos";
import FindBarge from "./FindBarge";
import Offers from "./Offers";

const pages = [
  { label: "trading_settings", component: Settings },
  { label: "trading_enter_cargo_header", component: EnterCargo },
  { label: "trading_your_cargos", component: YourCargos },
  { label: "trading_find_barge", component: FindBarge },
  { label: "trading_offers", component: Offers },
];

function TradingOil() {
  const [selectedPage, setSelectedPage] = useState(pages[0].label);
  const { t } = useTranslation();

  const handleChange = (_, newValue) => {
    setSelectedPage(newValue);
  };

  return (
    <SpotbargeLayout title={t("trading_menu")}>
      <TabContext value={selectedPage}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={selectedPage} onChange={handleChange}>
              {pages.map((page, index) => (
                <Tab key={index} label={t(page.label)} value={page.label} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          {pages.map((page, index) => (
            <TabPanel key={index} value={page.label} sx={{ width: "100%" }}>
              {React.createElement(page.component)}
            </TabPanel>
          ))}
        </Grid>
      </TabContext>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(TradingOil, {
  fallback: <GeneralErrorPage />,
});
