import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledGrid = styled(Grid)({
  padding: 20,
  paddingTop: 2,
  paddingBottom: 2,
  maxWidth: 300,
  width: "max-content",
});

const StyledRadio = styled(Radio)({
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const mapTypes = [
  { value: "normal", label: "Default type" },
  { value: "light", label: "Light map" },
  { value: "dark", label: "Dark map" },
  { value: "satelite", label: "Satelite" },
];

const MapTypeMenu = ({ styleValue, handleStyleChange }) => {
  return (
    <StyledGrid>
      <FormControl>
        <Typography variant="h6" component="div" textAlign="center" pb={1}>
          Map type
        </Typography>
        <RadioGroup value={styleValue} onChange={handleStyleChange}>
          {mapTypes.map((type) => (
            <FormControlLabel
              key={type.value}
              value={type.value}
              control={<StyledRadio />}
              label={type.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </StyledGrid>
  );
};

export default MapTypeMenu;
