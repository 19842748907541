import React, { useState, memo } from "react";
import { Grid, Button, Divider, Menu, Tooltip } from "@mui/material";
import {
  FilterAlt,
  Map as MapIcon,
  Adjust as AdjustIcon,
  Cached,
  Fullscreen as FullscreenIcon,
  DirectionsBoat,
} from "@mui/icons-material";
import isEqual from "lodash/isEqual";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import FiltersMenu from "./menus/FiltersMenu";
import FleetMenu from "./menus/FleetMenu";
import MapTypeMenu from "./menus/MapTypeMenu";
import SidebarButton from "./SidebarButton";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledGrid = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: "165px",
  transform: "translateY(-50%)",
  bottom: 0,
  left: 12,
  height: "305px",
  width: "70px",
  zIndex: 9999,
  backgroundColor: "white",
  borderRadius: "5px",
  display: "grid",
  gridAutoRows: "min-content",
  overflow: "hidden",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
  "& button": {
    height: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    zoom: 0.8,
    top: "220px",
  },
}));

const MapSidebar = ({
  handleClear,
  handleDataRefresh,
  loading,
  changeMapStyle,
  mapStyle,
  filters,
  setFilters,
  clearFilters,
  defaultFilters,
  handleFullscreenToggle,
  mapRef,
  fleet,
  handleUpsertModal,
  handleDeleteFleet,
  removeVesselFromFleet,
  showOnlyFleet,
  onlyFleet,
  handleFleetShipSelect,
  selectedFleetsToShow,
  handleFleetShow,
  handleFleetShare,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isFleetOpen, setIsFleetOpen] = useState(false);
  const [styleValue, setStyleValue] = useState(mapStyle);
  const [expanded, setExpanded] = useState(false);

  const filterHasChanges =
    JSON.stringify(filters) !== JSON.stringify(defaultFilters) || onlyFleet;

  const handleClick = (event, isMap, isFleet) => {
    setIsMapOpen(isMap);
    setIsFleetOpen(isFleet);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStyleChange = (event) => {
    setStyleValue(event.target.value);
    changeMapStyle(event.target.value);
  };

  return (
    <StyledGrid container elevation={6}>
      <SidebarButton
        title="Ship filters"
        icon={<FilterAlt />}
        onClick={handleClick}
        hasChanges={filterHasChanges}
      />
      <Divider />
      <SidebarButton
        title="Manage your fleet"
        icon={<DirectionsBoat />}
        onClick={(event) => handleClick(event, false, true)}
      />
      <Divider />
      <SidebarButton
        title="Map type"
        icon={<MapIcon />}
        onClick={(event) => handleClick(event, true, false)}
      />
      <Divider />
      <SidebarButton
        title="Reset map to initial state"
        icon={<AdjustIcon />}
        onClick={handleClear}
      />
      <Divider />
      <SidebarButton
        title="Click here to refresh map"
        icon={
          <Cached
            sx={{
              animation: loading ? `${rotate} 1s linear infinite` : "none",
            }}
          />
        }
        onClick={handleDataRefresh}
      />
      <Divider />
      <SidebarButton
        title="Full screen"
        icon={<FullscreenIcon />}
        onClick={handleFullscreenToggle}
      />
      <Menu
        container={mapRef.current}
        sx={{
          zIndex: 9999,
          marginLeft: "10px",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isMapOpen ? (
          <MapTypeMenu
            styleValue={styleValue}
            handleStyleChange={handleStyleChange}
          />
        ) : isFleetOpen ? (
          <FleetMenu
            expanded={expanded}
            setExpanded={setExpanded}
            filterHasChanges={filterHasChanges}
            fleet={fleet}
            handleUpsertModal={handleUpsertModal}
            handleDeleteFleet={handleDeleteFleet}
            removeVesselFromFleet={removeVesselFromFleet}
            mapRef={mapRef}
            handleFleetShipSelect={handleFleetShipSelect}
            handleFleetShare={handleFleetShare}
          />
        ) : (
          <FiltersMenu
            filters={filters}
            setFilters={setFilters}
            clearFilters={clearFilters}
            expanded={expanded}
            setExpanded={setExpanded}
            filterHasChanges={filterHasChanges}
            showOnlyFleet={showOnlyFleet}
            onlyFleet={onlyFleet}
            selectedFleetsToShow={selectedFleetsToShow}
            handleFleetShow={handleFleetShow}
          />
        )}
      </Menu>
    </StyledGrid>
  );
};

export default memo(MapSidebar, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.fleet, nextProps.fleet) &&
    isEqual(prevProps.filters, nextProps.filters) &&
    isEqual(prevProps.selectedFleetsToShow, nextProps.selectedFleetsToShow) &&
    prevProps.loading === nextProps.loading &&
    prevProps.onlyFleet === nextProps.onlyFleet
  );
});
