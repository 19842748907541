import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  MenuItem,
  Autocomplete,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import OutlinedSection from "../../atoms/OutlinedSection";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeList } from 'react-window';
import { DateTime } from "luxon";
import { useTradingPost } from "../../../request";

function EnterCargo() {
  const { t } = useTranslation();
  const [loadStart, setLoadStart] = useState("");
  const [loadEnd, setLoadEnd] = useState("");
  const [productType, setProductType] = useState("");
  const [product, setProduct] = useState("");
  const [volume, setVolume] = useState("");
  const [density, setDensity] = useState("");
  const [loadTerminal, setLoadTerminal] = useState("");
  const [dischargeTerminal, setDischargeTerminal] = useState("");
  const [extraLoadTerminal, setExtraLoadTerminal] = useState(null);
  const [extraDischargeTerminal, setExtraDischargeTerminal] = useState(null);
  const [tanks, setTanks] = useState([]);
  const [preCargo, setPreCargo] = useState("");
  const [heating, setHeating] = useState(false);
  const [heatingTemperature, setHeatingTemperature] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", severity: "" });
  const [productOptions, setProductOptions] = useState([]);
  const [portOptions, setPortOptions] = useState([]);
  const [saveAsStandardRoute, setSaveAsStandardRoute] = useState(false);
  const [errors, setErrors] = useState([]);

  const post = useTradingPost();

  useEffect(() => {
    import("../data/products.json").then((module) => setProductOptions(module.default));
    import("../data/ports.json").then((module) => setPortOptions(module.default));
  }, []);

  const handleClear = () => {
    setLoadStart("");
    setLoadEnd("");
    setProductType("");
    setProduct("");
    setVolume("");
    setDensity("");
    setLoadTerminal("");
    setDischargeTerminal("");
    setExtraLoadTerminal(null);
    setExtraDischargeTerminal(null);
    setTanks([]);
    setPreCargo([]);
    setHeating(false);
    setHeatingTemperature("");
    setComments("");
    setErrors([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMessage({ text: "", severity: "" });
    setErrors([]);
    try {
      const res = await post("save-cargo", {
        loadStart,
        loadEnd,
        productType,
        product: product?.label,
        volume,
        density,
        loadTerminal: loadTerminal?.label,
        dischargeTerminal: dischargeTerminal?.label,
        extraLoadTerminal: extraLoadTerminal?.label,
        extraDischargeTerminal: extraDischargeTerminal?.label,
        tanks,
        preCargo,
        heating,
        heatingTemperature,
        comments,
        saveAsStandardRoute,
      });
      if (!res) {
        setMessage({
          text: "Failed to save cargo details. Please try again or contact us.",
          severity: "error",
        });
        return;
      } else if (res.error) {
        setMessage({ text: res.error, severity: "error" });
      } else if ("errors" in res) {
        setErrors(res.errors || []);
      } else {
        setMessage({ text: "Cargo details saved successfully.", severity: "success" });
        handleClear();
      }
    } catch (err) {
      console.error(err);
      setMessage({
        text: "Failed to save cargo details. Please try again or contact us.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTankChange = (value) => (e) => {
    if (e.target.checked) {
      setTanks((prev) => [...prev, value]);
    } else {
      setTanks((prev) => prev.filter((v) => v !== value));
    }
  };

  const handlePreCargoChange = (value) => (e) => {
    if (e.target.checked) {
      setPreCargo((prev) => [...prev, value]);
    } else {
      setPreCargo((prev) => prev.filter((v) => v !== value));
    }
  };

  const renderRow = ({ index, style, props }) => {
    const { children } = props;
    const flatChildren = children.flatMap(child => [child.props.children[0], ...child.props.children[1].props.children]);
    return (
      <div style={style} onMouseDown={(e) => e.preventDefault()}>
        {flatChildren[index]}
      </div>
    );
  };

  const renderErrors = () => {
    if (errors.length === 0) return null;
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        <Typography variant="h6">There were errors with your submission:</Typography>
        <List dense disablePadding sx={{ padding: 0, margin: 0 }}>
          {errors.map((error, index) => (
            <ListItem key={index} sx={{ padding: '2px 0' }}>
              <ListItemText primary={`- ${error.msg}`} />
            </ListItem>
          ))}
        </List>
      </Alert>
    );
  };

  return (
    <SpotbargeLayout title={t("trading_enter_cargo_header")}>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {message.text && (
        <Alert severity={message.severity} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}
      {renderErrors()}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <OutlinedSection title={`1. ${t("trading_enter_cargo_trip_basics")}`} sx={{ flex: 1 }}>
          <Box sx={{ p: 1 }}>
            <DateRangePicker
              id="load-date"
              label={t("trading_enter_cargo_load_date")}
              sx={{ width: '100%' }}
              onChange={(newValue) => {
                setLoadStart(newValue[0]?.toFormat('yyyy-MM-dd') || "");
                setLoadEnd(newValue[1]?.toFormat('yyyy-MM-dd') || "");
              }}
              slots={{ field: SingleInputDateRangeField }}
              name="allowedRange"
              minDate={DateTime.now()}
            />
          </Box>
          <Box sx={{ p: 1, display: 'flex', gap: 2 }}>
            <TextField
              id="product-type"
              label={t("trading_enter_cargo_product_type")}
              select
              value={productType}
              onChange={(e) => {
                setProduct(null);
                setProductType(e.target.value);
              }}
              fullWidth
              sx={{ flex: 1 }}
            >
              <MenuItem value="cpp">CPP (clean petroleum products)</MenuItem>
              <MenuItem value="dpp">DPP (dirty petroleum products)</MenuItem>
            </TextField>
            <Autocomplete
              id="product-select"
              options={
                productType === "cpp"
                  ? productOptions.filter(option => !option.category.endsWith("(DPP)"))
                  : productType === "dpp"
                    ? productOptions.filter(option => option.category.endsWith("(DPP)"))
                    : []
              }
              groupBy={(option) => option?.category ?? ""}
              getOptionLabel={(option) => option?.label ?? ""}
              disabled={!productType}
              value={product}
              sx={{ flex: 1 }}
              onChange={(event, newValue) => setProduct(newValue)}
              renderInput={(params) => <TextField {...params} label={t("trading_enter_cargo_product")} />}
            />
          </Box>
          <Box sx={{ p: 1 }}>
            <TextField
              id="volume"
              label={t("trading_enter_cargo_volume")}
              type="number"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              fullWidth
              slotProps={{
                htmlInput: { min: 1, max: 15000, step: 1 }
              }}
            />
          </Box>
          <Box sx={{ p: 1 }}>
            <TextField
              id="density"
              label={t("trading_enter_cargo_density")}
              type="number"
              value={density}
              onChange={(e) => setDensity(e.target.value)}
              fullWidth
              slotProps={{
                htmlInput: { min: 0.01, max: 1.10, step: 0.01 }
              }}
            />
          </Box>
        </OutlinedSection>
        <OutlinedSection title={`2. ${t("trading_enter_cargo_area")}`} sx={{ flex: 1 }}>
          <Box sx={{ p: 1 }}>
            <Autocomplete
              id="load-terminal"
              options={portOptions}
              groupBy={(option) => option?.category ?? ""}
              getOptionLabel={(option) => option?.label ?? ""}
              value={loadTerminal}
              sx={{ flex: 1 }}
              onChange={(event, newValue) => setLoadTerminal(newValue)}
              renderInput={(params) => <TextField {...params} label={t("trading_enter_cargo_load_terminal")} />}
              ListboxComponent={(props) => (
                <FixedSizeList
                  height={200}
                  itemCount={props.children.flatMap(child => [child.props.children[0], ...child.props.children[1].props.children]).length}
                  itemSize={46}
                  width="100%"
                  {...props}
                >
                  {({ index, style }) => renderRow({ index, style, props })}
                </FixedSizeList>
              )}
            />
          </Box>
          {extraLoadTerminal !== null && (
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                id="extra-load-terminal"
                options={portOptions}
                groupBy={(option) => option?.category ?? ""}
                getOptionLabel={(option) => option?.label ?? ""}
                value={extraLoadTerminal}
                sx={{ flex: 1 }}
                onChange={(event, newValue) => setExtraLoadTerminal(newValue)}
                renderInput={(params) => <TextField {...params} label={t("trading_enter_cargo_extra_load_terminal")} />}
                ListboxComponent={(props) => (
                  <FixedSizeList
                    height={200}
                    itemCount={props.children.flatMap(child => [child.props.children[0], ...child.props.children[1].props.children]).length}
                    itemSize={46}
                    width="100%"
                    {...props}
                  >
                    {({ index, style }) => renderRow({ index, style, props })}
                  </FixedSizeList>
                )}
              />
              <IconButton onClick={() => setExtraLoadTerminal(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {extraLoadTerminal === null && extraDischargeTerminal === null && (
            <Box sx={{ p: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setExtraLoadTerminal("")}
                fullWidth
              >
                {t("trading_enter_cargo_add_load_terminal")}
              </Button>
            </Box>
          )}
          <Box sx={{ p: 1 }}>
            <Autocomplete
              id="discharge-terminal"
              options={portOptions}
              groupBy={(option) => option?.category ?? ""}
              getOptionLabel={(option) => option?.label ?? ""}
              value={dischargeTerminal}
              sx={{ flex: 1 }}
              onChange={(event, newValue) => setDischargeTerminal(newValue)}
              renderInput={(params) => <TextField {...params} label={t("trading_enter_cargo_discharge_terminal")} />}
              ListboxComponent={(props) => (
                <FixedSizeList
                  height={200}
                  itemCount={props.children.flatMap(child => [child.props.children[0], ...child.props.children[1].props.children]).length}
                  itemSize={46}
                  width="100%"
                  {...props}
                >
                  {({ index, style }) => renderRow({ index, style, props })}
                </FixedSizeList>
              )}
            />
          </Box>
          {extraDischargeTerminal !== null && (
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                id="extra-discharge-terminal"
                options={portOptions}
                groupBy={(option) => option?.category ?? ""}
                getOptionLabel={(option) => option?.label ?? ""}
                value={extraDischargeTerminal}
                sx={{ flex: 1 }}
                onChange={(event, newValue) => setExtraDischargeTerminal(newValue)}
                renderInput={(params) => <TextField {...params} label={t("trading_enter_cargo_extra_discharge_terminal")} />}
                ListboxComponent={(props) => (
                  <FixedSizeList
                    height={200}
                    itemCount={props.children.flatMap(child => [child.props.children[0], ...child.props.children[1].props.children]).length}
                    itemSize={46}
                    width="100%"
                    {...props}
                  >
                    {({ index, style }) => renderRow({ index, style, props })}
                  </FixedSizeList>
                )}
              />
              <IconButton onClick={() => setExtraDischargeTerminal(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {extraLoadTerminal === null && extraDischargeTerminal === null && (
            <Box sx={{ p: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setExtraDischargeTerminal("")}
                fullWidth
              >
                {t("trading_enter_cargo_add_discharge_terminal")}
              </Button>
            </Box>
          )}
        </OutlinedSection>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <OutlinedSection title="3. Tanks" sx={{ flex: 1, height: 'fit-content' }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Box sx={{ flex: 1, p: 1, display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("coated")}
                    onChange={handleTankChange("coated")}
                  />
                }
                label="Coated"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("mildsteel")}
                    onChange={handleTankChange("mildsteel")}
                  />
                }
                label="Mildsteel"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("stainless_steel")}
                    onChange={handleTankChange("stainless_steel")}
                  />
                }
                label="Stainless Steel"
              />
            </Box>
            <Box sx={{ flex: 1, p: 1, display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("stripped")}
                    onChange={handleTankChange("stripped")}
                  />
                }
                label="Stripped"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("inert_till_8")}
                    onChange={handleTankChange("inert_till_8")}
                  />
                }
                label="Inert till 8%"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanks.includes("gasfree")}
                    onChange={handleTankChange("gasfree")}
                  />
                }
                label="Gasfree"
              />
            </Box>
          </Box>
        </OutlinedSection>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <OutlinedSection title={`4. ${t("trading_enter_cargo_pre_cargo")}`} sx={{ flex: 1 }}>
              <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preCargo.includes("oxyfree")}
                      onChange={handlePreCargoChange("oxyfree")}
                    />
                  }
                  label="Oxy Free"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preCargo.includes("bio")}
                      onChange={handlePreCargoChange("bio")}
                    />
                  }
                  label="Bio Free"
                />
              </Box>
            </OutlinedSection>
            <OutlinedSection title={`5. ${t("trading_enter_cargo_heating")}`} sx={{ flex: 1 }}>
              <Box sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={heating}
                      onChange={(e) => setHeating(e.target.checked)}
                    />
                  }
                  label={t("trading_enter_cargo_heating_required")}
                />
              </Box>
              {heating && (
                <Box sx={{ p: 1 }}>
                  <TextField
                    id="heating-temperature"
                    label={t("trading_enter_cargo_heating_temperature")}
                    type="number"
                    value={heatingTemperature}
                    onChange={(e) => setHeatingTemperature(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                    }}
                    slotProps={{
                      htmlInput: { min: 10, max: 100, step: 0.01 }
                    }}
                  />
                </Box>
              )}
            </OutlinedSection>
          </Box>
          <Box>
            <OutlinedSection title={`6. ${t("trading_enter_cargo_comments")}`}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id="comments"
                  label={t("trading_enter_cargo_comments")}
                  multiline
                  rows={4}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  slotProps={{ input: { maxLength: 100000 } }}
                />
              </Box>
            </OutlinedSection>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, flexDirection: { xs: 'column', md: 'row' } }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveAsStandardRoute}
                  onChange={(e) => setSaveAsStandardRoute(e.target.checked)}
                />
              }
              label={t("trading_enter_cargo_save_as_standard_route")}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ width: { sm: '100%', md: 'fit-content' }, minWidth: 150 }}
            >
              {t("trading_enter_cargo_submit")}
            </Button>
          </Box>
        </Box>
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(EnterCargo, {
  fallback: <GeneralErrorPage />,
});
