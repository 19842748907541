import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingPost, useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

function EnterBarge() {
  const { t } = useTranslation();
  const [loadEta, setLoadEta] = useState(null);
  const [bargeName, setBargeName] = useState("");
  const [bargeSizeM3, setBargeSizeM3] = useState("");
  const [bargeSizeDwt, setBargeSizeDwt] = useState("");
  const [bargeEuropeNr, setBargeEuropeNr] = useState("");
  const [tankType, setTankType] = useState("");
  const [heating, setHeating] = useState(false);
  const [preCargo1, setPreCargo1] = useState("");
  const [preCargo2, setPreCargo2] = useState("");
  const [preCargo3, setPreCargo3] = useState("");
  const [areas, setAreas] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", severity: "" });
  const [ships, setShips] = useState([]);

  const post = useTradingPost();
  const get = useTradingGet();

  useEffect(() => {
    import("../data/ships.json").then((module) => setShips(module.default));
  }, []);

  const handleClear = () => {
    setLoadEta(null);
    setBargeName("");
    setBargeSizeM3("");
    setBargeSizeDwt("");
    setBargeEuropeNr("");
    setTankType("");
    setHeating(false);
    setPreCargo1("");
    setPreCargo2("");
    setPreCargo3("");
    setAreas([]);
    setTanks([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await post("save-barge", {
        loadEta,
        bargeName,
        bargeSizeM3,
        bargeSizeDwt,
        bargeEuropeNr,
        tankType,
        heating,
        preCargo1,
        preCargo2,
        preCargo3,
        areas,
        tanks,
      });
      if (!res || "error" in res) {
        setMessage({
          text: "Failed to save barge details. Please try again or contact us.",
          severity: "error",
        });
        return;
      }
      setMessage({ text: "Barge details saved successfully.", severity: "success" });
      handleClear();
    } catch (err) {
      console.error(err);
      setMessage({
        text: "Failed to save barge details. Please try again or contact us.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBargeNameChange = async (e, newValue) => {
    setBargeName(newValue);
    if (newValue) {
      try {
        const res = await get("barge-info", { name: newValue });
        if (res && !("error" in res)) {
          if (res.cubicM3) setBargeSizeM3(res.cubicM3);
          if (res.deadweight) setBargeSizeDwt(res.deadweight);
          if (res.europNr) setBargeEuropeNr(res.europNr);
          if (res.tankType) setTankType(res.tankType.toLowerCase().replace(" ", "_"));
          if (res.heating !== undefined) setHeating(res.heating === "Yes");
        }
      } catch (error) {
        console.error("Failed to fetch barge info:", error);
      }
    }
  };

  const handleAreaChange = (area) => (e) => {
    if (e.target.checked) {
      setAreas([...areas, area]);
    } else {
      setAreas(areas.filter((a) => a !== area));
    }
  };

  const handleTankChange = (tank) => (e) => {
    if (e.target.checked) {
      setTanks([...tanks, tank]);
    } else {
      setTanks(tanks.filter((t) => t !== tank));
    }
  };

  const today = DateTime.now();

  return (
    <SpotbargeLayout title={t("trading_enter_barge_enter_barge_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {message.text && (
          <Alert severity={message.severity} sx={{ mb: 2 }}>
            {message.text}
          </Alert>
        )}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <OutlinedSection title={`1. ${t("trading_enter_barge_trip_basics")}`} sx={{ flex: 1 }}>
            <Box sx={{ p: 1 }}>
              <DatePicker
                label={t("trading_enter_barge_load_eta")}
                value={loadEta}
                onChange={(newValue) => setLoadEta(newValue?.toFormat('yyyy-MM-dd') || "")}
                minDate={today}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Autocomplete
                id="barge-name"
                options={ships}
                value={bargeName}
                onChange={handleBargeNameChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("trading_enter_barge_barge_name")}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-size-m3"
                label={t("trading_enter_barge_barge_size_m3")}
                type="number"
                value={bargeSizeM3}
                onChange={(e) => setBargeSizeM3(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-size-dwt"
                label={t("trading_enter_barge_barge_size_dwt")}
                type="number"
                value={bargeSizeDwt}
                onChange={(e) => setBargeSizeDwt(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-europe-nr"
                label={t("trading_enter_barge_barge_europe_nr")}
                type="number"
                value={bargeEuropeNr}
                onChange={(e) => setBargeEuropeNr(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="tank-type"
                label={t("trading_enter_barge_tank_type")}
                select
                value={tankType}
                onChange={(e) => setTankType(e.target.value)}
                fullWidth
              >
                <MenuItem value="coated">{t("trading_enter_barge_coated")}</MenuItem>
                <MenuItem value="mild_steel">{t("trading_enter_barge_mild_steel")}</MenuItem>
                <MenuItem value="stainless_steel">{t("trading_enter_barge_stainless_steel")}</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ p: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={heating}
                    onChange={(e) => setHeating(e.target.checked)}
                  />
                }
                label={t("trading_enter_barge_heating")}
              />
            </Box>
          </OutlinedSection>
          <Box sx={{ flex: 1 }}>
            <OutlinedSection title={`2. ${t("trading_enter_barge_last_3_pre_cargos")}`}>
              <Box sx={{ p: 1 }}>
                <TextField
                  id="pre-cargo-1"
                  label={t("trading_enter_barge_pre_cargo_1")}
                  type="text"
                  value={preCargo1}
                  onChange={(e) => setPreCargo1(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box sx={{ p: 1 }}>
                <TextField
                  id="pre-cargo-2"
                  label={t("trading_enter_barge_pre_cargo_2")}
                  type="text"
                  value={preCargo2}
                  onChange={(e) => setPreCargo2(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box sx={{ p: 1 }}>
                <TextField
                  id="pre-cargo-3"
                  label={t("trading_enter_barge_pre_cargo_3")}
                  type="text"
                  value={preCargo3}
                  onChange={(e) => setPreCargo3(e.target.value)}
                  fullWidth
                />
              </Box>
            </OutlinedSection>
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' } }}>
              <OutlinedSection title={`3. ${t("trading_enter_barge_available_for_areas")}`} sx={{ flex: 1 }}>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("ARA")}
                        onChange={handleAreaChange("ARA")}
                      />
                    }
                    label="ARA"
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("FARAG")}
                        onChange={handleAreaChange("FARAG")}
                      />
                    }
                    label="FARAG"
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("RHINE")}
                        onChange={handleAreaChange("RHINE")}
                      />
                    }
                    label="RHINE"
                  />
                </Box>
              </OutlinedSection>
              <OutlinedSection title={`4. ${t("trading_enter_barge_tanks")}`} sx={{ flex: 1 }}>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("gasfree")}
                        onChange={handleTankChange("gasfree")}
                      />
                    }
                    label={t("trading_enter_barge_gasfree")}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("oxy_free")}
                        onChange={handleTankChange("oxy_free")}
                      />
                    }
                    label={t("trading_enter_barge_oxy_free")}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("bio_free")}
                        onChange={handleTankChange("bio_free")}
                      />
                    }
                    label={t("trading_enter_barge_bio_free")}
                  />
                </Box>
              </OutlinedSection>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, flexDirection: { xs: 'column', md: 'row' } }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ width: { sm: '100%', md: 'fit-content' }, minWidth: 150 }}
              >
                {t("trading_enter_barge_submit")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(EnterBarge, {
  fallback: <GeneralErrorPage />,
});
