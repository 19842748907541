import { useState, memo } from "react";
import { Paper, Grid, Tabs, Tab, Box, Fade } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import "firebase/compat/auth";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../../GeneralErrorPage";
import Settings from "./Settings";
import EnterBarge from "./EnterBarge";
import YourBarges from "./YourBarges";
import FindCargo from "./FindCargo";
import Offers from "./Offers";

const pages = [
  { label: "trading_settings", component: Settings },
  { label: "trading_enter_barge_header", component: EnterBarge },
  { label: "trading_your_barges", component: YourBarges },
  { label: "trading_find_cargo", component: FindCargo },
  { label: "trading_offers", component: Offers },
];

function TradingShipping() {
  const [selectedPage, setSelectedPage] = useState(pages[0].label);
  const { t } = useTranslation();

  const handleChange = (_, newValue) => {
    setSelectedPage(newValue);
  };

  return (
    <SpotbargeLayout title={t("trading_menu")}>
      <TabContext value={selectedPage}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs value={selectedPage} onChange={handleChange}>
              {pages.map((page, index) => (
                <Tab key={index} label={t(page.label)} value={page.label} />
              ))}
            </Tabs>
          </Box>
        </Grid>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          {pages.map((page, index) => (
            <TabPanel key={index} value={page.label} sx={{ width: "100%" }}>
              <page.component />
            </TabPanel>
          ))}
        </Grid>
      </TabContext>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(TradingShipping, {
  fallback: <GeneralErrorPage />,
});
